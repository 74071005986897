.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-family: 'Montserrat', sans-serif;
  height: 100%;
  width: 90%;
  background-color: #00aa50;
  padding: 1rem 0;
}

.loader h1,
.loader h2 {
  color: #fff;
  text-align: center;
  font-size: 24px;
  margin: 0;
  padding: 0;
}

.loader h2 {
  font-size: 42px;
  font-weight: bold;
}

.loader .fact {
  font-family: 'Minute', sans-serif;
  height: 30vh;
  width: 30vh;
  padding: 1rem;
  font-size: 3vh;
  border-radius: 50%;
  background-color: #ffe300;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: Rotate 5s linear infinite;
}

.loader .fact p {
  padding: 2rem;
  text-align: center;
  transform: rotate(20deg);
}

.loader img {
  height: 64px;
  width: 64px;
  animation: 3s Spinner linear infinite;
}

@keyframes Spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes Rotate {
  0% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}
