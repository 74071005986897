.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  height: 10vh;
}

.header img {
  height: 24px;
}

.header img:nth-child(3) {
  visibility: hidden;
}
