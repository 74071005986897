.leaderBoardDiv {
  background-color: #ffdcec;
  padding-top: 1rem;
  text-align: center;
}

.leaderBoardList {
  list-style: decimal;
  display: inline-block;
  overflow-y: scroll;
  max-height: 16rem;
  text-align: center;
  margin: 0;
  padding-right: 0.5rem;
}

.leaderBoardList::-webkit-scrollbar {
  width: 0.5rem;
  margin: 0.5rem;
}

.leaderBoardList::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0.5rem;
  margin: 0.5rem;
}

.leaderBoardList::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.5rem;
}

.leaderBoardList::-webkit-scrollbar-thumb:hover {
  background: #555;
}

td {
  padding-top: 0.85rem;
  text-align: left;
  width: 100%;
}

td:nth-child(1) {
  padding-right: 1rem;
}

p {
  padding: 1rem 0;
  margin: 0 auto;
}

.budgetIconDiv {
  height: 26rem;
  background-size: cover;
  background-image: url('../Assets/icon-budget.svg');
  background-repeat: no-repeat;
  background-color: #ffdcec;
  background-position: center 100%;
}
