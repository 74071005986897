.clicker-container {
  min-width: 320px;
  max-width: 414px;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 2rem;
  overflow: hidden;
}

.number-of-clicks,
.seconds {
  color: #000;
  font-size: 2rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  margin: 0;
}

.number-of-clicks {
  border: 3px solid #000;
  padding: 2rem 0;
  font-size: 4rem;
  width: 100%;
  text-align: center;
  box-shadow: 8px 8px 0 0 #00aa50;
}

.progress {
  height: 2rem;
  width: 100%;
  background-color: #e5e8e8;
  display: flex;
  justify-content: flex-end;
}

.bar {
  animation: Progress 20s linear;
}

.clicker {
  border: none;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  padding: 0;
  position: relative;
}

.clicker:active {
  box-shadow: 0 0 5px 5px #7800f0;
}

.clicker[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

.click-text {
  font-family: 'Minute', sans-serif;
  position: absolute;
  top: -30px;
  right: -50px;
  transform: rotate(25deg);
  font-size: 2rem;
  color: #ff3c1b;
  margin: 0;
}

.clicker img {
  height: 200px;
  width: 200px;
}

.clicker span {
  position: absolute;
  font-size: 4rem;
  border-radius: 50%;
  animation: EmojiRain 1s linear;
  pointer-events: none;
}

@keyframes EmojiRain {
  0% {
    transform: translate(0) scale(1) rotate(var(--rotate));
  }
  100% {
    transform: translate(var(--translateX), -150px) scale(0);
  }
}

@keyframes Progress {
  0% {
    width: 100%;
    background-color: #7800f0;
  }
  50% {
    background-color: #ff3c1b;
  }
  100% {
    background-color: #ff3c1b;
    width: 0%;
  }
}
