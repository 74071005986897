.subscriptionForm {
  font-family: 'Montserrat', sans-serif;
  width: 100%;
}

/* Style inputs */
.subscriptionForm input[type='text'],
.subscriptionForm select {
  width: 100%;
  padding: 1rem 1rem;
  background-color: #e6e7e8;
  margin: 1rem 0;
  display: block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Style the submit button */
.subscriptionForm input[type='submit'],
input[type='button'] {
  box-shadow: 5px 5px white, 5px 5px 0px 2px black;
  border: 2px solid black;
  border-radius: 0;
  background: white;
  color: black;
  font-size: 16px;
  padding: 1rem;
  font-weight: bold;
  width: 8rem;
  display: block;
}

.subscriptionForm .subscriptionInfoDiv {
  text-align: center;
  width: 90%;
  margin: 0 auto 1rem auto;
}

.subscriptionForm .subscriptionOption {
  display: block;
  padding-left: 1rem;
  text-indent: -1rem;
  font-size: 16px;
  margin-bottom: 1rem;
}

.subscriptionForm .privacyPolicyOption {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 15px;
  padding-left: 1rem;
  font-size: 16px;
  margin-bottom: 2rem;
}

.subscriptionForm input {
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  position: relative;
  top: -1px;
}

.subscriptionForm form {
  margin: 0 auto;
  max-width: 80%;
}

.subscriptionForm img {
  display: block;
  margin: auto;
}

.subscriptionForm h3 {
  text-align: center;
  font-size: 28px;
}

.subscriptionForm h1 {
  font-size: 40px;
}

.subscriptionForm .scoreSectionDiv {
  border: 3px solid black;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  box-shadow: 5px 5px #00bb5f, 5px 5px 0px 2px #00bb5f;
  margin-bottom: 2rem;
}

.subscriptionForm .playAgainLink {
  text-decoration: none;
  box-shadow: 5px 5px white, 5px 5px 0px 2px black;
  border: 2px solid black;
  color: black;
  padding: 1rem;
  width: 6rem;
  margin: 0 auto 2rem auto;
  display: block;
  text-align: center;
}

.subscriptionForm .playAgainButton {
  border: none;
  font-weight: bold;
  background: white;
  cursor: pointer;
}

.subscriptionForm .inputSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
