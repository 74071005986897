@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

@font-face {
  font-family: 'Minute';
  src: url('./Assets/Minute_font.otf');
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  width: 100%;
  max-width: 414px;
}
