.frontPageDiv {
  font-family: 'Montserrat', sans-serif;
}

.frontPageDiv .gameInfoTextDiv {
  width: 80%;
  margin: auto;
  text-align: center;
}

.frontPageDiv h2,
.frontPageDiv h3 {
  margin: 0;
  padding: 1rem 0;
  font-weight: 900;
  font-size: 24px;
}

.frontPageDiv h2 {
  padding: 1rem 0 2rem 0;
  font-size: 36px;
}

.frontPageDiv input {
  margin-top: 1rem;
  height: 3rem;
  width: 100%;
  box-shadow: 5px 5px #4000ff;
  text-align: center;
  border: 2px solid black;
  font-size: 1.2rem;
  font-weight: 900;
}

.frontPageDiv .startButtonDiv {
  padding: 2rem 0;
}

.frontPageDiv .startGameButton {
  box-shadow: 5px 5px white, 5px 5px 0px 2px black;
  border: 2px solid black;
  color: black;
  padding: 1rem;
  width: 6rem;
  margin: 0 auto;
  display: block;
  text-align: center;
  font-weight: bold;
  background: white;
  cursor: pointer;
}

.frontPageDiv .logoDiv {
  height: 4rem;
  text-align: center;
  position: relative;
  margin-top: 1rem;
  vertical-align: middle;
}

.frontPageDiv .circleLogo {
  height: 4rem;
  width: 4rem;
  left: 0;
  margin-left: 20px;
  position: absolute;
  margin-bottom: 10px;
  vertical-align: middle;
}

.frontPageDiv .exoveLogo {
  height: 2rem;
  margin-top: 1rem;
}
