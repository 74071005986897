.socialMediaIconsDiv {
  text-align: center;
  background-color: black;
  color: white;
  font-family: 'Montserrat', sans-serif;
}

.icons {
  display: flex;
  padding-inline: 4em;
  justify-content: space-between;
  text-align: center;
  padding-block: 0.5em;
}

.icons > a {
  text-decoration: none;
  color: white;
  font-size: 1.5em;
}
